import request from '@/utils/request'

export function getOneByAdIdApi(data) {
    return request({
        url: '/advert/adidTotalConfig/getOneByAdId',
        method: 'post',
        data
    })
}

export function saveApi(data) {
    return request({
        url: '/advert/adidTotalConfig/save',
        method: 'post',
        data
    })
}




