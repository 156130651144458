<template>
<div>
  <el-row>
    <el-col :span="6">
      <el-input v-model="listQuery.pd" clearable @clear="reloadList" placeholder="pd查询"/>
    </el-col>
    <el-col :span="2" style="margin-left:15px">
      <el-button type="primary" @click="search">查询</el-button>
    </el-col>
  </el-row>

  <el-table :data="tableData">
    <el-table-column fixed fit align="center" type="index" min-width="10" />
    <el-table-column width="150" property="pd" label="pd"></el-table-column>
    <el-table-column width="150" property="model" label="机型"></el-table-column>
    <el-table-column width="100" property="phoneSysVersion" label="系统版本"></el-table-column>
    <el-table-column width="150" property="mac" label="mac"></el-table-column>
    <el-table-column label="操作" align="center" min-width="70" >
      <template slot-scope="scope">
        <el-button
            size="mini"
            type="primary"
            @click="handleadd(scope.$index, scope.row)">添加</el-button>

      </template>
    </el-table-column>

  </el-table>

<!--  <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />-->
  <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                 background @current-change="handleCurrentChange" layout="total,prev, pager, next" :page-size="listQuery.size" :total="total">
  </el-pagination>

</div>
</template>

<script>
import {listByadIdApi,addApi} from "@/api/pd";


export default {
  name: "todayPd",
  components: { },
  props: ['row'],
  data(){
    return{
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
        adId: '',
        pd:''
      },
      adName:'',
      tableData: [],
    };
  },
  created() {
    this.getList();

  },
  watch:{
    row: function (n ,o) {
      console.info(o);
      console.info(n.id)
      console.info("watch")
      this.listQuery.current = 1;
      this.tableData = [];
      this.getList();
    }
  },
  methods:{
    search() {
      if(this.listQuery.pd == '') return
      this.getList(true)
      // todayListByadIdApi(this.listQuery).then(response => {
      //   if(response.code === 1) {
      //     this.listQuery.current = 1
      //     this.tableData = response.body.data
      //   }
      // })
    },
    reloadList() {
      this.getList(true)
    },
    getList(type) {
      this.listQuery.adId = this.row.id;
      this.adName = this.row.adName;

      // console.info(11223)
      // console.info(this.listQuery)
      if(type) {
         this.listQuery.current = 1
      }
      listByadIdApi(this.listQuery).then(response => {
        // console.info(5555)
        // console.info(response)
        this.tableData = response.body.data.records;
        this.total = response.body.data.total;


      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    moreData(){
      this.listQuery.current = this.listQuery.current+1;
      this.getList();

    },
    handleadd(index, row){
      console.info(index,row);
      row.adName = this.adName
      console.info(row);
      //开始添加
      addApi(row).then(() => {

        this.$notify({
          title: 'Success',
          message: '添加成功',
          type: 'success',
          duration: 2000
        })
      })

    }
  }
}
</script>

<style scoped>

</style>
