<template>
  <div>

    <el-row>
      <el-button type="primary" @click="handleCreate">添加</el-button>
      <el-button type="primary" @click="saveOrder">排序同步(会清除redis缓存,没必要不要点击)</el-button>
    </el-row>
    <!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

        <el-form-item label="广告类型" prop="adTypeName">
          <el-input v-model="temp.adTypeName" disabled="disabled" />
        </el-form-item>

        <el-form-item label="广告对象" prop="adObjectName">

          <el-select v-model="temp.adObjectName"  placeholder="广告对象">
            <el-option
                v-for="item in adOptions"
                :key="item.name"
                :label="item.nameZh"
                :value="item.name">
            </el-option>
          </el-select>

        </el-form-item>


        <el-form-item label="开关" prop="onOff">
          <el-select v-model="temp.onOff" placeholder="开关">
            <el-option
                v-for="item in onOffOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="madid" prop="madid">
          <el-input v-model="temp.madid" />
        </el-form-item>
        <el-form-item label="加载概率" prop="loadRate">
          <el-input v-model="temp.loadRate" />
        </el-form-item>
        <el-form-item label="展示概率" prop="showRate">
          <el-input v-model="temp.showRate" />
        </el-form-item>
        <el-form-item label="展示上限" prop="maxShow">
          <el-input v-model="temp.maxShow" />
        </el-form-item>
        <el-form-item label="点击上限" prop="maxClick">
          <el-input v-model="temp.maxClick" />
        </el-form-item>
        <el-form-item label="时间间隔" prop="intervalTime">
          <el-input v-model="temp.intervalTime" />
        </el-form-item>

        <el-form-item label="是否有声播放" prop="vvSound">
          <el-select v-model="temp.vvSound" placeholder="是否有声播放">
            <el-option
                v-for="item in vvSoundOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否自动销毁" prop="aaDestory">
          <el-select v-model="temp.aaDestory" placeholder="是否有声播放">
            <el-option
                v-for="item in aaDestoryOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="轮询时间(毫秒)" prop="swTime">
          <el-input v-model="temp.swTime" />
        </el-form-item>


        <el-form-item label="清除视图" prop="auClear">
          <el-select v-model="temp.auClear" placeholder="是否清除视图">
            <el-option
                v-for="item in auClearOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <!--表单 END -->
    <br />
    <el-table ref="singleTable" :data="tableData" border fit style="width: 100%;height: 100%">

      <el-table-column fixed fit align="center" type="index" min-width="5" />

      <el-table-column prop="adTypeName" label="广告类型" min-width="20" />
      <el-table-column prop="adObjectName" label="广告对象" min-width="20" />

      <el-table-column prop="onOff" label="开关 " min-width="20" >
        <template slot-scope="scope">
          {{scope.row.onOff === 1? '开启':'关闭'}}
        </template>
      </el-table-column>

<!--      <el-table-column prop="mappid" label="mappid" min-width="20" />-->

      <el-table-column prop="madid" label="madid" min-width="20" />

      <el-table-column prop="loadRate" label="加载概率" min-width="20" />

      <el-table-column prop="showRate" label="展示概率" min-width="20" />

      <el-table-column prop="maxShow" label="展示上限" min-width="20" />

      <el-table-column prop="maxClick" label="点击上限" min-width="20" />

      <el-table-column prop="intervalTime" label="时间间隔" min-width="20" />

      <el-table-column label="操作" align="center" min-width="100" >
        <template slot-scope="scope">

          <el-button

              type="success"
              @click="up(scope.$index, scope.row)"> <i class="el-icon-top"></i>  </el-button>
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>


          <el-button
              size="mini"
              type="success"
              @click="down(scope.$index, scope.row)"><i class="el-icon-bottom"></i></el-button>

        </template>
      </el-table-column>
    </el-table>
<!--    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />-->
    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" layout="total,prev, pager, next" :page-size="listQuery.size" :total="total">
    </el-pagination>
    <div style="height: 30px;" />
  </div>
</template>

<script>
import { saveOrder,adsort,listApi,addApi,updateApi,delApi } from '@/api/adidDetailConfig'

import { adslistApi } from '@/api/adObjects'
export default {
  name: "adsDetailConfig",
  props: ['row'],
  components: { },
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 20,
        adId: '',
      },
      dialogFormVisible: false,
      dialogFormTotalConfig:false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        adObjectName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        onOff: [{ required: true, message: '不能为空', trigger: 'blur' }],
        madid: [{ required: true, message: '不能为空', trigger: 'blur' }],
        loadRate: [{ required: true, message: '不能为空', trigger: 'blur' }],
        showRate: [{ required: true, message: '不能为空', trigger: 'blur' }],
        maxShow: [{ required: true, message: '不能为空', trigger: 'blur' }],
        maxClick: [{ required: true, message: '不能为空', trigger: 'blur' }],
        intervalTime: [{ required: true, message: '不能为空', trigger: 'blur' }],

      },
      temp: {
        adTypeNme:'',
        id: undefined,
        adId: '',
        adObjectName: '',
        onOff: '',
        mappid: '',
        madid: '',
        loadRate: '',
        showRate: '',
        maxShow: '',
        maxClick: '',
        vvSound: '',
        aaDestory: '',
        swTime: '',

        auClear:'',
      },
      tableData: [],
      onOffOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],
      vvSoundOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],

      aaDestoryOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],

      auClearOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],

      adOptions:[
      ]
    }
  },
  created() {
    this.getList();
    this.getAdsList();
  },
  watch:{
    row: function (n ,o) {
      console.info(n,o)
      this.getList();
      this.getAdsList();
    }
  },
  methods:{
    getAdsList(){
      adslistApi(this.row.id).then(response => {
        if(response.body){
          this.adOptions = response.body.records
        }
      })
    },
    getList() {
      this.listQuery.adId = this.row.id;
      console.info(this.listQuery)
      listApi(this.listQuery).then(response => {
        this.tableData = response.body.records
        this.total = response.body.total
      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    saveOrder(){

      let len = this.tableData.length
      if(len === 0){
        return
      }
      let o = this.tableData[0]

      saveOrder({"adId":o.adId}).then(() => {
        this.$notify({
          title: 'Success',
          message: '操作成功',
          type: 'success',
          duration: 2000
        })
      })


    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.adId = this.row.id
          console.log(this.temp);
          addApi(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetTemp() {
      this.temp = {
        adTypeName:this.row.adTypeName,
        id: undefined,
        adId: '',
        adObjectName: '',
        onOff: '',
        mappid: '',
        madid: '',
        loadRate: '',
        showRate: '',
        maxShow: '',
        maxClick: '',
        vvSound: '',
        aaDestory: '',
        swTime: '',

        auClear:'',
      }
    },
    handleUpdate(index, row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      this.temp.adTypeName = this.row.adTypeName
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    up(index, row){

      console.info(index)
      console.info(row)
      if(index === 0){
        return
      }

      let len = this.tableData.length;
      if(len === 0){
        return
      }

      let o = this.tableData[index]
      let n = this.tableData[index-1]
      let tableDataNew = [ ... this.tableData]
      tableDataNew[index-1] = o
      tableDataNew[index] = n
      this.tableData = tableDataNew

      //获取互换位置的id 和排序编号

      this.adsort(tableDataNew)


    },

    down(index, row){

      console.info(index)
      console.info(row)
      let len = this.tableData.length
      if(len === index+1){
        return
      }
      let o = this.tableData[index]
      let n = this.tableData[index+1]
      let tableDataNew = [ ... this.tableData]
      tableDataNew[index+1] = o
      tableDataNew[index] = n
      this.tableData = tableDataNew

      //获取互换位置的id 和排序编号

      this.adsort(tableDataNew)


    },
    adsort(data){
      let len = data.length
      let sortData = {};
      for(let i = 0;i<len;i++){
        sortData[data[i].id] = i
      }

      console.info(55555)
      console.info(sortData)

      //adsort
      adsort(sortData).then(() => {
        // const index = this.tableData.findIndex(v => v.id === this.temp.id)
        // this.tableData.splice(index, 1, this.temp)
        // this.dialogFormVisible = false
        // this.$notify({
        //   title: 'Success',
        //   message: '修改成功',
        //   type: 'success',
        //   duration: 2000
        // })
      })



    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          tempData.adId = this.row.id;
          console.info("tempData")
          console.info(tempData)
          updateApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },

    handleTotalSet(index, row){
      console.log(index, row);
      this.dialogFormTotalConfig = true
      this.pdTotalsetTemp = row;
    },
    handleDetailedSet(index, row){
      console.log(index, row);

    },
    totalConfigVisible(v){
      this.dialogFormTotalConfig = v
    }


  }
}

</script>

<style scoped>


</style>
