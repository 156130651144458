import request from '@/utils/request'



export function saveOrder(data) {
    return request({
        url: '/advert/adidDetailConfig/saveOrder',
        method: 'post',
        data
    })
}


export function adsort(data) {
    return request({
        url: '/advert/adidDetailConfig/adsort',
        method: 'post',
        data
    })
}

export function listApi(data) {
    return request({
        url: '/advert/adidDetailConfig/list',
        method: 'post',
        data
    })
}

export function addApi(data) {
    return request({
        url: '/advert/adidDetailConfig/add',
        method: 'post',
        data
    })
}

export function updateApi(data) {
    return request({
        url: '/advert/adidDetailConfig/edit',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/adidDetailConfig/del',
        method: 'post',
        data
    })
}



