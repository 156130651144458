<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>配置</el-breadcrumb-item>
      <el-breadcrumb-item>广告位</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <el-row>
      <el-button type="primary" @click="handleCreate">添加</el-button>

      <el-select v-model="listQuery.appId"  placeholder="app" style="margin-left: 30px" clearable @change="qappIdChange" >
        <el-option
            v-for="item in appOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>

    </el-row>
<!--    总配置 B-->
    <el-dialog title="总配置" :visible.sync="dialogFormTotalConfig">
        <AdsTotalConfig  ref="adstotal" :row="pdTotalsetTemp"  @dialogTotalConfigVisible="totalConfigVisible" />

    </el-dialog>
<!--    总配置 E-->
    <!--    明细配置 B-->
    <el-dialog title="明细配置" :visible.sync="dialogFormDetailConfig" style="width:2200px;margin-left: -400px" >
       <AdsDetailConfig :row="pdDetailsetTemp"  />
    </el-dialog>
    <!--    明细配置 E-->

    <!--    当日pd B-->
    <el-dialog title="当日pd" :visible.sync="dialogtodayPd" @closed="closedTodayPdset">

      <todayPd :row="todayPdTemp"  v-if="dialogtodayPd"/>

    </el-dialog>

    <!--    当日pd E-->
    <!--    之前pd B-->
    <el-dialog title="当日之前pd" :visible.sync="dialoglistPd" @closed="closedListPdTemp">

      <zqPd :row="listPdTemp"  v-if="dialoglistPd"/>

    </el-dialog>

    <!--    之前pd E-->

<!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
        <el-form-item label="应用" prop="appId">
          <el-select v-model="temp.appId" v-bind:disabled="dialogStatus === 'update'"  style="width:500px"   placeholder="应用">
            <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="单点运营" prop="openSingle">
          <el-select v-model="temp.openSingle" style="width:500px" placeholder="是否开启单点精准运营">
            <el-option
                v-for="item in attributeOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="广告位名称" prop="adName">
          <el-input v-model="temp.adName" />
        </el-form-item>

        <el-form-item label="广告位类型" prop="adType">
          <el-select v-model="temp.adType" style="width:500px" placeholder="广告位类型">
            <el-option
                v-for="item in adTypeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

<!--        <el-form-item label="sdk版本" prop="versionName">-->
<!--          <el-input v-model="temp.versionName" />-->
<!--        </el-form-item>-->

<!--        <el-form-item label="签名校验" prop="sign">-->
<!--          <el-input v-model="temp.sign" />-->
<!--        </el-form-item>-->

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <!--表单 END -->
    <br />
    <div style="background: #fff ">
    <el-table ref="singleTable" :data="tableData" border fit style="width: 100%;height: 100%">

      <el-table-column fixed fit align="center" type="index" min-width="20" />
      <el-table-column prop="id" label="广告位ID" min-width="50" />
      <el-table-column prop="appName" label="应用名称" min-width="50" />
      <el-table-column prop="adName" label="广告位名称" min-width="50" />
      <el-table-column prop="openSingle" label="是否开启单点精准运营 " min-width="50" >

        <template slot-scope="scope">
          {{scope.row.openSingle === 1? '开启':'关闭'}}
        </template>

      </el-table-column>


      <el-table-column prop="adTypeName" label="广告类型" min-width="50" />


<!--      <el-table-column prop="versionName" label="sdk版本名称" min-width="50" />-->

<!--      <el-table-column prop="sign" label="签名校验" min-width="80" />-->

      <el-table-column label="pd添加" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handletodayPdset(scope.$index, scope.row)">今日</el-button>
          <el-button
              size="mini"
              @click="handlelistPdset(scope.$index, scope.row)">之前</el-button>
        </template>
      </el-table-column>

      <el-table-column label="配置" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleTotalSet(scope.$index, scope.row)">总配置</el-button>
          <el-button
              size="mini"
              @click="handleDetailedSet(scope.$index, scope.row)">明细配置</el-button>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" layout="total,prev, pager, next" :page-size="listQuery.size" :total="total">
    </el-pagination>
    <div style="height: 30px;" />
    </div>

  </div>
</template>

<script>
import {listApi, addApi, updateApi, delApi, getAdTypeApi} from '@/api/ads'
import { listAllApi } from '@/api/apps'

import AdsTotalConfig from "@/components/adsTotalConfig";
import AdsDetailConfig from "@/components/adsDetailConfig";

import todayPd from "@/components/todayPd";
import zqPd from "@/components/zqPd";
export default {
  name: "ads",
  components: {AdsDetailConfig, AdsTotalConfig ,todayPd,zqPd},
  inject: ['load','closeLoad'],
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
        appId:''
      },
      dialogFormVisible: false,
      dialogFormTotalConfig:false,
      dialogFormDetailConfig:false,

      dialogtodayPd:false,
      dialoglistPd:false,

      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        appId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        adName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        openSingle: [{ required: true, message: '不能为空', trigger: 'blur' }],
        adType: [{ required: true, message: '不能为空', trigger: 'blur' }],

      },
      temp: {
        id: undefined,
        adName: '',
        adType:'',
        appId: '',
        openSingle: '',
        versionName: '',
        sign: '',

      },
      tableData: [],
      appOptions:[],
      adTypeOptions:[],
      attributeOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],
      pdTotalsetTemp:{

      },
      pdDetailsetTemp:{

      },
      todayPdTemp:{

      },
      listPdTemp:{

      }
    }
  },
  created() {
    this.getList();
    this.getInitOptions();
    this.getAdTypeOptions();
  },
  methods:{
    getAdTypeOptions(){
      getAdTypeApi({}).then(response => {
        this.adTypeOptions = response.body
      })
    },
    qappIdChange(val){
      console.info(val)
      this.listQuery.current = 1;
      this.getList();

    },

    getInitOptions(){
      listAllApi({current: 1,size: 1000,}).then(response => {
        this.appOptions = response.body.records
      })
    },
    getList() {
      this.load()
      this.listLoading = true
      listApi(this.listQuery).then(response => {
        this.tableData = response.body.records
        this.total = response.body.total
        this.closeLoad()
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)
      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log(this.temp);
          addApi(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        adName: '',
        adType:'',
        appId: '',
        openSingle: '',
        versionName: '',
        sign: '',
      }
    },
    handleContant(index, row) {
      console.info(index,row);
    },
    handleUpdate(index, row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          updateApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },

    handleTotalSet(index, row){
      this.dialogFormTotalConfig = true
      this.pdTotalsetTemp = row;

    },
    handleDetailedSet(index, row){
      this.dialogFormDetailConfig = true
      this.pdDetailsetTemp = row;

    },

    totalConfigVisible(v){
      this.dialogFormTotalConfig = v
    },

    handletodayPdset(index, row){
      this.dialogtodayPd = true
      this.todayPdTemp = row
    },
    closedTodayPdset() {
      this.dialogtodayPd = false
    },
    handlelistPdset(index, row){
      this.dialoglistPd = true
      this.listPdTemp = row
    },
    closedListPdTemp() {
      this.dialoglistPd = false
    }

  }
}

</script>

<style scoped>


</style>
